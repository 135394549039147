import { RouteLocationNormalizedGeneric, createRouter, createWebHistory } from 'vue-router';

import { App } from 'vue';

import { storeToRefs } from 'pinia';

import { useTitle } from '@vueuse/core';
import routes from './routes';
import piniaStore from '@/store';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
    // scrollBehavior(to, from, savedPosition) { // 滾動行為
    //     return { top: 0 };
    // }
});

// 在 login 頁面還不能完全做到攔截不正確登入訊息，我們必須在router做更進一步處理
router.beforeEach(async (to: RouteLocationNormalizedGeneric) => {
    const elStatusStore = piniaStore.useElStatusStore;
    const { elStatusState } = storeToRefs(elStatusStore);
    const userStore = piniaStore.useUserStore;
    const { userState } = storeToRefs(userStore);

    if (!elStatusState.value.isLoading) {
        elStatusStore.startLoading(); // 開啟遮罩

        // [-]如果頁面編輯中 詢問是否離開
        if (elStatusStore.elStatusState.isEdit) {
            const app: App = inject('app');
            const leaveConfirm = await app.config.globalProperties.$notify('warning', '提醒！', '您尚在編輯模式，<br />可能有未儲存的資料，<br />請確認是否要離開此頁面。', 0, true);

            if (!leaveConfirm) {
                throw new Error('編輯中，取消導航');

                // return false;
            } else {
                elStatusStore.endEdit();
            }
        }

        // [-]目的路由在meta上是否有設置requireAuth: true 來判斷是否需要驗證
        if (to.meta.requireAuth) {
            let token: string | null = userState.value.access_token;

            if (!token) {
                // [-] 重新整理後，token會消失，所以要從localStorage找看看
                token = localStorage.getItem('access_token');
                const userData = localStorage.getItem('user_data');

                if (token) { // 有東西的話， 把token存到store裡面
                    userState.value.data = JSON.parse(userData || '{}');

                    if (!userState.value.isLogin) {
                        userState.value.isLogin = true;
                    }
                } else {
                    elStatusStore.endLoading(); // 關閉遮罩
                    userStore.signOut();
                    return { name: 'Login' };
                }
            }

            if (!userState.value.isLogin) {
                elStatusStore.endLoading(); // 關閉遮罩
                userStore.signOut();
                return { name: 'Login' };
            }
        }

        // [-]根據不同情況 導向不同首頁
        if (to.path === '/' || to.path === '/index') {
            if (userState.value.isLogin) {
                elStatusStore.endLoading(); // 關閉遮罩
                return { name: 'PatientList' }; // 登入就去病患列表
            } else {
                elStatusStore.endLoading(); // 關閉遮罩
                return { name: 'Login' }; // 沒登入就是去登入頁面
            }
        }

        // [#] 之後要改成導航會員列表
        if (to.path === '/login' && userState.value.isLogin) { // 如果是登入頁面，登出
            elStatusStore.endLoading(); // 關閉遮罩
            userStore.signOut();
        }
    } else {
        console.error('router isLoading...');
        return false;
    }
});

router.beforeResolve(async (_to: RouteLocationNormalizedGeneric) => {});

// 可以帶to, from, failure
router.afterEach((to: RouteLocationNormalizedGeneric) => {
    const elStatusStore = piniaStore.useElStatusStore;

    // 刷新head title
    useTitle(`${to.meta.title} - 洗腎護照`);

    elStatusStore.endLoading(); // 關閉遮罩
});

router.onError((error: Error) => {
    const elStatusStore = piniaStore.useElStatusStore;

    console.error(error);

    elStatusStore.endLoading();
});

export default router;
