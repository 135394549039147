import { RouteRecordRaw } from 'vue-router';

// [-] Pinia
import { storeToRefs } from 'pinia';
import piniaStore from '@/store';

import { sendRequest } from '@/hooks/useApi';

const routes: RouteRecordRaw[] = [
    {
        name: 'Login',
        path: '/login',
        component: async () => import('@/pages/Login.vue'),
        meta: { title: '登入', icon: 'login' }
    },
    {
        name: 'Forget',
        path: '/forget',
        component: async () => import('@/pages/Forget.vue'),
        meta: { title: '忘記密碼', icon: 'address-book' }
    },
    {
        name: 'PatientList',
        path: '/patient-list',
        component: async () => import('@/pages/PatientList.vue'),
        meta: { requireAuth: true, title: '患者列表', icon: 'ballot' },
        redirect: '/patient-list/1',
        children: [
            {
                name: 'PatientListP',
                path: ':page',
                component: async () => import('@/components/organism/orga-patientTbody.vue'),
                meta: { requireAuth: true, title: '患者列表', icon: 'ballot' }
                // props: () => ({ patientList: [] })
            }
        ],
        beforeEnter: async () => {
            const patientStore = piniaStore.usePatientStore;
            patientStore.resetPatient();
        }
    },
    {
        name: 'Patient',
        path: '/patient/:patient_id(\\d+)', // 只能是數字
        component: async () => import('@/pages/Patient.vue'),
        meta: { requireAuth: true, title: '患者資料', icon: 'ballot' },
        children: [
            {
                name: 'TubeInfo',
                path: 'tube-info',
                component: async () => import('@/pages/patient/TubeInfo.vue'),
                meta: { requireAuth: true, title: '廔管資料', icon: 'image' }
            },
            {
                name: 'SurgeryRecords',
                path: 'surgery-records',
                component: async () => import('@/pages/patient/SurgeryRecords.vue'),
                meta: { requireAuth: true, title: '手術紀錄', icon: 'ballot' }
            },
            {
                name: 'OutpatientRecords',
                path: 'outpatient-records',
                component: async () => import('@/pages/patient/OutpatientRecords.vue'),
                meta: { requireAuth: true, title: '回診紀錄', icon: 'book_2' },
                redirect: 'outpatient-records/1',
                children: [
                    {
                        name: 'OutpatientRecordsList',
                        path: ':page(\\d+)',
                        component: async () => import('@/components/organism/orga-outpatientTbody.vue'),
                        meta: { requireAuth: true, title: '回診紀錄', icon: 'book_2' }
                        // props: () => ({ patientList: [] })
                    }
                ]
            },
            {
                name: 'MessageBoard',
                path: 'message-board',
                component: async () => import('@/pages/patient/MessageBoard.vue'),
                meta: { requireAuth: true, title: '留言板', icon: 'forum' }
            },
            {
                name: 'DataAnalysis',
                path: 'data-analysis',
                component: async () => import('@/pages/patient/DataAnalysis.vue'),
                meta: { requireAuth: true, title: '數據分析', icon: 'dns' }
            }
        ],
        beforeEnter: async (to) => {
            const elStatusStore = piniaStore.useElStatusStore;
            const userStore = piniaStore.useUserStore;
            const { userState } = storeToRefs(userStore);
            const userPermissions = userState.value.data.role.permissions;

            const patientId = Number(to.params?.patient_id) || 0;

            // [-]沒選定患者就去導向患者列表
            if (!patientId) {
                elStatusStore.endLoading();
                return { name: 'PatientList', params: { page: 1 } };
            }

            // [-]判斷權限，沒權限就登出
            if (to.path !== `/patient/${patientId}`
                && !userPermissions.some((item) => {
                    return (
                        to.path === `/patient/${patientId}/${item.href}`
                        || to.path === `/patient/${patientId}/${item.href}/${to.params?.page}`
                    );
                })
            ) {
                elStatusStore.endLoading();
                userStore.signOut();
                return { name: 'Login' };
            }

            // [-]如果是剛進病患頁面 導向到廔管資料
            if (to.path === `/patient/${patientId}`) {
                const params = { patient_id: patientId };

                elStatusStore.endLoading();
                return { name: 'TubeInfo', params };
            }

            const patientStore = piniaStore.usePatientStore;
            const { patientState } = storeToRefs(patientStore);

            // [-]當前病患資料同這個病患的時候 直接導向
            if (patientState.value.patient_id === patientId) { return; }

            // [-]上述條件不符合時重新取得病患資料
            try {
                const result = await sendRequest(`/api/present_pat/simpledata/${patientId}`);
                if (!result?.status) { throw new Error('取得病患資料錯誤!'); }

                patientStore.updatePatient(result.data);
            } catch (error) {
                console.error('取得病患資料錯誤', error);
                elStatusStore.endLoading();
                return { name: 'PatientList' };
            }
        }
    },
    {
        name: 'Develop',
        path: '/develop',
        component: async () => import('@/pages/develop/index.vue'),
        redirect: '/develop/ui-ux-set',
        meta: { title: '開發用頁面', icon: 'address-book' },
        children: [
            {
                name: 'CTP',
                path: 'clear-test-page',
                component: async () => import('@/pages/develop/ctp.vue'),
                meta: { title: 'CTP', icon: 'deployed_code' }
            },
            {
                name: 'UIUXSet',
                path: 'ui-ux-set',
                component: async () => import('@/pages/develop/UIUXSet.vue'),
                meta: { title: '主題樣式', icon: 'address-book' }
            },
            {
                name: 'IconList',
                path: 'icon-list',
                component: async () => import('@/pages/develop/IconList.vue'),
                meta: { title: 'Icon列表', icon: 'address-book' }
            }
        ]
    },
    {
        // 未定義網址 => 404
        name: 'PageNotFound',
        path: '/:pathMatch(.*)*',
        component: async () => import('@/pages/PageNotFound.vue'),
        meta: { title: '頁面不存在!!', icon: 'cross-circle' }
    }
];

export default routes;
