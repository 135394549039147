<script setup lang="ts">
    const { name = 'circle', href = '', isWaitting = false } = defineProps<{
        name?: string
        href?: string
        isWaitting?: boolean
    }>();

    // 跳轉
    function jump() {
        if (href !== '') {
            const router = useRouter();
            router.push(href);
        }
    }
</script>

<template>
    <div class="icon" :class="{ btn: href !== '', isWaitting }" @click="jump">
        <svg class="svg">
            <use :xlink:href="`#${name}`" />
        </svg>
        <svg class="loading">
            <use xlink:href="#cycle" />
        </svg>
    </div>
</template>

<style lang="scss">
    .icon {
        --cubeColor: var(--themeColor);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.2s $cubic-FiSo;
        overflow: hidden;
        fill: #bbb;
        @include setSize(100%, 100%);
        .svg {
            display: flex;
            align-items: center;
            justify-content: center;
            @include setSize(100%, 100%);
        }

        &.btn {
            cursor: pointer;
            &.cube {
                fill: #f2f2f2;
                background: $colorMain;
                @include setSize(50px, 50px);
                padding: 10px;
                border-radius: 15px;
                opacity: 0.8;
            }

            &:hover,
            &.current {
                fill: $colorMain;
            }
        }

        &.cube {
            background: var(--cubeColor);
            padding: 10px;
            border-radius: 20px;
            cursor: pointer;
            opacity: .8;
            fill: #f2f2f2;
            @include setSize(50px, 50px);
            &:hover{ transform: scale3d(1.1, 1.1, 1); }
        }

        .loading {
            display: none;
            @include setSize(100%, 100%);
            animation: circle 0.6s steps(30) infinite;
        }

        &.isWaitting {
            pointer-events: none;
            .svg { display: none; }
            .loading {
                @include setFlex();
            }
        }
    }
</style>
